import React, { useState } from 'react';
import { Lead } from '../types/Lead';
import { LeadStatus } from './LeadStatus';
import { pushToOPF, pushToMAX } from '../services/pushService';
import { format, setHours, setMinutes, isWithinInterval } from 'date-fns';
import { ResponseModal } from './ResponseModal';

interface LeadsTableProps {
  leads: Lead[];
  onLeadUpdate?: (lead: Lead) => void;
}

export const LeadsTable: React.FC<LeadsTableProps> = ({ leads, onLeadUpdate }) => {
  const [pushingLeadId, setPushingLeadId] = useState<string | null>(null);
  const [modalData, setModalData] = useState<{ isOpen: boolean; response: any }>({
    isOpen: false,
    response: null,
  });

  const handlePush = async (lead: Lead, service: 'OPF' | 'MAX') => {
    if (pushingLeadId) return;
    setPushingLeadId(lead.id);

    try {
      const result = await (service === 'OPF' ? pushToOPF(lead) : pushToMAX(lead));

      if (onLeadUpdate && result.updatedLead) {
        onLeadUpdate(result.updatedLead);
      }

      setModalData({
        isOpen: true,
        response: {
          success: result.success,
          data: result.response,
          timestamp: new Date().toISOString(),
        },
      });
    } catch (error: any) {
      setModalData({
        isOpen: true,
        response: {
          success: false,
          error: error.message,
          timestamp: new Date().toISOString(),
        },
      });
    } finally {
      setPushingLeadId(null);
    }
  };

  const isWithinCustomHours = (country: string): boolean => {
    const currentTime = new Date();
    const ranges: { [key: string]: { start: string; end: string } } = {
      TR: { start: '10:00', end: '17:00' },
      FR: { start: '10:00', end: '19:00' },
      CZ: { start: '10:00', end: '18:30' },
    };

    const defaultRange = { start: '09:00', end: '19:00' };
    const { start, end } = ranges[country] || defaultRange;

    const startTime = setMinutes(setHours(new Date(), parseInt(start.split(':')[0])), parseInt(start.split(':')[1]));
    const endTime = setMinutes(setHours(new Date(), parseInt(end.split(':')[0])), parseInt(end.split(':')[1]));

    return isWithinInterval(currentTime, { start: startTime, end: endTime });
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Contact
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Response
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Time
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                CC Hours
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {leads.map((lead) => (
              <tr key={`lead-${lead.id}-${lead.Status}`}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {lead.Name} {lead.Surname}
                  </div>
                  <div className="text-sm text-gray-500">{lead.Country}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {lead.Phonecc} {lead.Phone}
                  </div>
                  <div className="text-sm text-gray-500">{lead.Email}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <LeadStatus status={pushingLeadId === lead.id ? 'PUSHING' : lead.Status} />
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-500 max-w-xs overflow-hidden text-ellipsis">
                    {lead.Response && (
                      <details>
                        <summary className="cursor-pointer hover:text-gray-700">View Response</summary>
                        <pre className="mt-2 text-xs bg-gray-50 p-2 rounded overflow-auto max-h-32">
                          {lead.Response}
                        </pre>
                      </details>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {format(new Date(lead.Date), 'PPp')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      isWithinCustomHours(lead.Country) ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {isWithinCustomHours(lead.Country) ? 'Within Hours' : 'Out of Hours'}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handlePush(lead, 'OPF')}
                      disabled={pushingLeadId === lead.id || lead.Status === 'PUSHED_OPF'}
                      className={`inline-flex items-center px-3 py-1 rounded-md text-sm font-medium ${
                        pushingLeadId === lead.id
                          ? 'bg-gray-300 cursor-not-allowed'
                          : lead.Status === 'PUSHED_OPF'
                          ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                          : 'bg-blue-600 text-white hover:bg-blue-700'
                      }`}
                    >
                      {pushingLeadId === lead.id ? (
                        <>
                          <svg
                            className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            />
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                          </svg>
                          Pushing...
                        </>
                      ) : (
                        'Push to OPF'
                      )}
                    </button>
                    <button
                      onClick={() => handlePush(lead, 'MAX')}
                      disabled={pushingLeadId === lead.id || lead.Status === 'PUSHED_MAX'}
                      className={`inline-flex items-center px-3 py-1 rounded-md text-sm font-medium ${
                        pushingLeadId === lead.id
                          ? 'bg-gray-300 cursor-not-allowed'
                          : lead.Status === 'PUSHED_MAX'
                          ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                          : 'bg-blue-600 text-white hover:bg-blue-700'
                      }`}
                    >
                      {pushingLeadId === lead.id ? (
                        <>
                          <svg
                            className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            />
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                          </svg>
                          Pushing...
                        </>
                      ) : (
                        'Push to MAX'
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ResponseModal
        isOpen={modalData.isOpen}
        onClose={() => setModalData({ isOpen: false, response: null })}
        title="Push Response"
        response={modalData.response}
      />
    </>
  );
};
