import React from 'react';
import { Lead } from '../types/Lead';
import { format, isValid } from 'date-fns';

interface ActionLogProps {
  leads: Lead[];
}

interface LogEntry {
  timestamp: string;
  destination: string;
  type: string;
  status: string;
  message: string;
  leadName: string;
  success?: boolean;
  data?: any;
}

export const ActionLog: React.FC<ActionLogProps> = ({ leads }) => {
  const parseLogEntries = (lead: Lead): LogEntry[] => {
    if (!lead.Response || lead.Response === '') return [];

    try {
      let responses;
      // Handle different response formats
      if (lead.Response === 'Saved' || lead.Response === 'NON WORKING') {
        return [{
          timestamp: lead.Date || new Date().toISOString(),
          destination: 'SYSTEM',
          type: 'INFO',
          status: lead.Status,
          message: lead.Response,
          leadName: `${lead.Name} ${lead.Surname}`
        }];
      }

      try {
        responses = JSON.parse(lead.Response);
        responses = Array.isArray(responses) ? responses : [responses];
      } catch {
        // If not valid JSON, return as single text entry
        return [{
          timestamp: lead.Date || new Date().toISOString(),
          destination: 'SYSTEM',
          type: 'INFO',
          status: lead.Status,
          message: lead.Response,
          leadName: `${lead.Name} ${lead.Surname}`
        }];
      }

      return responses.map(response => ({
        timestamp: response.timestamp || lead.Date || new Date().toISOString(),
        destination: response.destination || 'SYSTEM',
        type: response.type || 'INFO',
        status: response.status || lead.Status,
        message: formatMessage(response),
        leadName: `${lead.Name} ${lead.Surname}`,
        success: response.success,
        data: response.data
      }));
    } catch (e) {
      // Return a simple entry for unparseable responses
      return [{
        timestamp: lead.Date || new Date().toISOString(),
        destination: 'SYSTEM',
        type: 'INFO',
        status: lead.Status,
        message: lead.Response || 'No response data',
        leadName: `${lead.Name} ${lead.Surname}`
      }];
    }
  };

  const formatMessage = (entry: any): string => {
    if (!entry) return 'No data';
    
    if (entry.type === 'ATTEMPT') {
      return `Attempting ${entry.data?.method || 'REQUEST'} to ${entry.data?.url || 'Unknown URL'}`;
    }
    if (entry.type === 'ERROR') {
      return `Error: ${entry.error?.message || entry.message || 'Unknown error'}`;
    }
    if (entry.data) {
      return typeof entry.data === 'string' 
        ? entry.data 
        : JSON.stringify(entry.data, null, 2);
    }
    return entry.message || 'No details available';
  };

  const formatDate = (dateString: string): string => {
    if (!dateString) return 'No date';
    try {
      const date = new Date(dateString);
      return isValid(date) ? format(date, 'HH:mm:ss') : 'Invalid date';
    } catch {
      return 'Invalid date';
    }
  };

  const getStatusColor = (status: string) => {
    if (!status) return 'bg-gray-100 text-gray-800';
    if (status.includes('PUSHED')) return 'bg-green-100 text-green-800';
    if (status === 'REJECTED') return 'bg-red-100 text-red-800';
    if (status === 'PUSHING') return 'bg-yellow-100 text-yellow-800';
    return 'bg-gray-100 text-gray-800';
  };

  const getTypeColor = (type: string) => {
    if (!type) return 'bg-gray-100 text-gray-800';
    switch (type.toUpperCase()) {
      case 'ATTEMPT': return 'bg-blue-100 text-blue-800';
      case 'ERROR': return 'bg-red-100 text-red-800';
      case 'RESPONSE': return 'bg-green-100 text-green-800';
      case 'INFO': return 'bg-gray-100 text-gray-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const allEntries = leads
    .flatMap(parseLogEntries)
    .filter(entry => entry && entry.timestamp) // Filter out invalid entries
    .sort((a, b) => {
      try {
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
      } catch {
        return 0;
      }
    });

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Action Log</h3>
            <p className="mt-1 text-sm text-gray-500">
              Detailed log of all lead actions and responses
            </p>
          </div>
        </div>
        
        <div className="mt-4 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                      Time
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Lead
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Type
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Destination
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {allEntries.length === 0 ? (
                    <tr>
                      <td colSpan={6} className="py-4 pl-4 pr-3 text-sm text-gray-500 text-center">
                        No actions logged yet
                      </td>
                    </tr>
                  ) : (
                    allEntries.map((entry, index) => (
                      <tr key={`${entry.leadName}-${entry.timestamp}-${index}`}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500">
                          {formatDate(entry.timestamp)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {entry.leadName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getTypeColor(entry.type)}`}>
                            {entry.type}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {entry.destination}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(entry.status)}`}>
                            {entry.status}
                          </span>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500">
                          <details className="cursor-pointer">
                            <summary className="hover:text-gray-700">View Details</summary>
                            <pre className="mt-2 text-xs bg-gray-50 p-2 rounded overflow-x-auto max-h-32">
                              {entry.message}
                            </pre>
                          </details>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};