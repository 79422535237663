import axios from 'axios';
import { Lead } from '../types/Lead';

const API_BASE_URL = 'https://app.nocodb.com/api/v2';
const TABLE_ID = 'm0k9j2buvv5o8zv';
const API_TOKEN = 'jfkx2Oa9t6WORAP6-vKKD1rY6R8mNH5F0I_lR89J';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'xc-token': API_TOKEN,
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

export const fetchLeads = async (): Promise<Lead[]> => {
  try {
    const leads: Lead[] = [];
    let page = 1;

    while (true) {
      const response = await api.get(`/tables/${TABLE_ID}/records`, {
        params: {
          limit: 100, // Specify the number of records you want per page
          page, // Specify the current page
        },
      });

      const fetchedLeads = response.data.list.map((lead: any) => ({
        id: lead.Id,
        Name: lead.Name || '',
        Surname: lead.Surname || '',
        Email: lead.Email || '',
        Phone: lead.Phone || '',
        Phonecc: lead.Phonecc || '',
        IP: lead.IP || '',
        Clickid: lead.Clickid || '',
        Country: lead.Country || '',
        Offer: lead.Offer || '',
        Password: lead.Password || '',
        Status: lead.Status || 'SAVED',
        Response: lead.Response || '',
        Date: lead.Date || new Date().toISOString(),
      }));

      leads.push(...fetchedLeads);

      // Check if this is the last page
      if (fetchedLeads.length < 100) {
        break;
      }

      page++;
    }

    return leads;
  } catch (error: any) {
    console.error('Failed to fetch leads:', error);
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const updateLeadStatus = async (lead: Lead, status: string, response: string): Promise<Lead> => {
  try {
    const updateData = {
      Id: lead.id,
      Status: status,
      Response: response
    };

    const updateResponse = await api.patch(`/tables/${TABLE_ID}/records`, updateData);

    if (!updateResponse.data) {
      throw new Error('Failed to update lead status');
    }

    return {
      ...lead,
      Status: status,
      Response: response
    };
  } catch (error: any) {
    console.error('Failed to update lead:', error);
    throw new Error(error.response?.data?.message || error.message);
  }
};