import axios from 'axios';
import { Lead } from '../types/Lead';
import { updateLeadStatus } from './api';
import { config } from '../config/config';

const OPF_API_URL = 'https://vip.dekikoy.com/tracker';

export const pushToOPF = async (lead: Lead) => {
  try {
    await updateLeadStatus(lead, 'PUSHING_OPF', 'Initiating OPF push...');

    const queryParams = new URLSearchParams({
      first_name: lead.Name,
      last_name: lead.Surname,
      email: lead.Email,
      password: lead.Password || '',
      phonecc: lead.Phonecc,
      phone: lead.Phone,
      user_ip: lead.IP,
      aff_sub: lead.Clickid || '95e0alpu3homy8cb',
      aff_sub3: lead.Offer || 'Test Offer',
      aff_id: config.api.opfAffId,
      offer_id: config.api.opfOfferId
    });

    const response = await axios.get(`${OPF_API_URL}?${queryParams.toString()}`, {
      validateStatus: (status) => status < 500,
      timeout: 30000,
      headers: {
        'Accept': 'application/json'
      }
    });

    const responseData = {
      success: response.status === 200,
      status: response.status,
      data: response.data,
      timestamp: new Date().toISOString()
    };

    await updateLeadStatus(
      lead, 
      response.status === 200 ? 'PUSHED_OPF' : 'REJECTED',
      JSON.stringify(responseData)
    );

    if (response.status !== 200) {
      throw new Error(`OPF API returned status ${response.status}`);
    }

    return {
      success: true,
      response: responseData,
      updatedLead: {
        ...lead,
        Status: 'PUSHED_OPF',
        Response: JSON.stringify(responseData)
      }
    };
  } catch (error: any) {
    const errorData = {
      success: false,
      error: {
        message: error.response?.data?.message || error.message,
        status: error.response?.status,
        data: error.response?.data
      },
      timestamp: new Date().toISOString()
    };

    await updateLeadStatus(lead, 'REJECTED', JSON.stringify(errorData));
    throw new Error(errorData.error.message);
  }
};

export const pushToMAX = async (lead: Lead) => {
  try {
    // Build the GET query parameters
    const queryParams = new URLSearchParams({
      link_id: '109',
      fname: lead.Name,
      lname: lead.Surname,
      email: lead.Email,
      fullphone: `${lead.Phonecc}${lead.Phone}`.replace(/[^0-9]/g, ''),
      ip: lead.IP,
      country: lead.Country,
      language: 'en',
      click_id: lead.Clickid || '',
      source: lead.Offer || '',
    });

    // Call the PHP script
    const response = await axios.get(`https://alchemytrade.org/components/maxpush.php?${queryParams.toString()}`, {
      validateStatus: (status) => status < 500,
      timeout: 30000,
      headers: {
        'Accept': 'application/json'
      }
    });
    const responseData = {
      success: response.status === 200,
      status: response.status,
      data: response.data,
      timestamp: new Date().toISOString(),
    };

    await updateLeadStatus(
      lead,
      response.status === 200 ? 'PUSHED_MAX' : 'REJECTED',
      JSON.stringify(responseData)
    );

    if (response.status !== 200) {
      throw new Error(`PHP script returned status ${response.status}: ${JSON.stringify(response.data)}`);
    }

    return {
      success: true,
      response: responseData,
      updatedLead: {
        ...lead,
        Status: 'PUSHED_MAX',
        Response: JSON.stringify(responseData),
      },
    };
  } catch (error: any) {
    const errorData = {
      success: false,
      error: {
        message: error.response?.data?.message || error.message,
        status: error.response?.status,
        data: error.response?.data,
      },
      timestamp: new Date().toISOString(),
    };

    console.error('MAX API Error:', {
      error: error.message,
      response: error.response?.data,
      status: error.response?.status,
    });

    await updateLeadStatus(lead, 'REJECTED', JSON.stringify(errorData));
    throw new Error(errorData.error.message);
  }
};
