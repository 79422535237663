import React from 'react';

interface LeadStatusProps {
  status: string;
}

export const LeadStatus: React.FC<LeadStatusProps> = ({ status }) => {
  const getStatusColor = () => {
    switch (status.toUpperCase()) {
      case 'PUSHED_OPF':
      case 'PUSHED_MAX':
        return 'bg-green-100 text-green-800';
      case 'NON WORKING':
        return 'bg-yellow-100 text-yellow-800';
      case 'PUSHING_OPF':
      case 'PUSHING_MAX':
      case 'PUSHING':
        return 'bg-blue-100 text-blue-800';
      case 'REJECTED':
        return 'bg-red-100 text-red-800';
      case 'SAVED':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor()}`}>
      {status}
    </span>
  );
};