import { useState, useEffect } from 'react';
import { Lead } from './types/Lead';
import { fetchLeads } from './services/api';
import { LeadsTable } from './components/LeadsTable';
import { ActionLog } from './components/ActionLog';
import { config } from './config/config';
import { LoginForm } from './components/LoginForm';

type TabType = 'leads' | 'log';

function App() {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [activeTab, setActiveTab] = useState<TabType>('leads');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const loadLeads = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const data = await fetchLeads();
      setLeads(data);
      setLastUpdated(new Date());
    } catch (err: any) {
      setError(err.message || 'Failed to fetch leads');
      console.error('Error loading leads:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleLeadUpdate = (updatedLead: Lead) => {
    setLeads(currentLeads => 
      currentLeads.map(lead => 
        lead.id === updatedLead.id ? updatedLead : lead
      )
    );
  };

  const handleLogin = (password: string) => {
    if (password === config.security.password) {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
    }
  };

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  if (!isAuthenticated) {
    return <LoginForm onLogin={handleLogin} />;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">LM</h1>
              <div className="text-sm text-gray-500 mt-1">
                {lastUpdated && (
                  <p>Last updated: {lastUpdated.toLocaleTimeString()}</p>
                )}
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={loadLeads}
                disabled={loading}
                className={`inline-flex items-center px-4 py-2 rounded-md text-white ${
                  loading
                    ? 'bg-blue-400 cursor-not-allowed' 
                    : 'bg-blue-600 hover:bg-blue-700'
                }`}
              >
                {loading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                    </svg>
                    Loading...
                  </>
                ) : (
                  'Load Leads'
                )}
              </button>
              <button
                onClick={() => {
                  localStorage.removeItem('isAuthenticated');
                  setIsAuthenticated(false);
                }}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md"
              >
                Logout
              </button>
            </div>
          </div>

          {error && (
            <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-400 text-red-700">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm">{error}</p>
                </div>
              </div>
            </div>
          )}

          <div className="mb-4 border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              <button
                onClick={() => setActiveTab('leads')}
                className={`${
                  activeTab === 'leads'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
              >
                Leads
              </button>
              <button
                onClick={() => setActiveTab('log')}
                className={`${
                  activeTab === 'log'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
              >
                Action Log
              </button>
            </nav>
          </div>

          {loading && leads.length === 0 ? (
            <div className="text-center py-12">
              <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-blue-500 border-t-transparent"></div>
              <div className="text-xl text-gray-600 mt-4">Loading leads...</div>
            </div>
          ) : leads.length === 0 ? (
            <div className="text-center py-12">
              <div className="text-xl text-gray-600">No leads loaded yet. Click "Load Leads" to fetch data.</div>
            </div>
          ) : (
            activeTab === 'leads' ? (
              <LeadsTable leads={leads} onLeadUpdate={handleLeadUpdate} />
            ) : (
              <ActionLog leads={leads} />
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default App;